<template>
    <div>
        <Form :model="formData" label-position="right" :label-width="100" :rules="ruleForm">
            <div style="margin: 0 10px;width:60%">
                <FormItem>
                    <Button type="primary" @click="openSelect">选择接收人</Button>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">接收人:</span>
                    <Input type="textarea" :disabled="true" v-model="formData.list" style="width:660px" rows="5"></Input>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">短信内容:</span>
                    <Input type="textarea" v-model="formData.content" style="width:660px" show-word-limit maxlength="400" rows="5"></Input>
                </FormItem>
                <div class="title" style="padding-left:100px;font-size:16px">
                    当前将发送短信共:
                    <span style="color:red">{{ resTotal || 0 }}</span>
                    条，预计费用:
                    <span style="color:red">{{ resFee || 0 }} 元</span>
                </div>
                <div class="title" style="padding-left:100px;">
                    <div style="margin:10px 0">计费说明:</div>
                    <div style="margin:10px 0">1、短信费用为0.07元/条；</div>
                    <div style="margin:10px 0">2、每70个字算1条。</div>
                </div>
            </div>
        </Form>
        <LiefengModal title="选择接收人" :fullscreen="true" :value="modalStatus" @input="modalData">
            <template v-slot:contentarea>
                <SelectMessageUser ref="selectRef" @handleSelect="handleSelect"></SelectMessageUser>
            </template>
            <template v-slot:toolsbar>
                <Button style="margin: 0 5px 0 13px" type="primary" @click="messageSend">保存</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal"
import SelectMessageUser from "../childrens/selectMessageUser.vue"
export default {
    props: {
        isShow: {
            type: Number,
            default: () => {
                return 0
            },
        },
        // formData:{
        //     type:Object,
        //     default:()=>{
        //         return {}
        //     }
        // }
    },
    data() {
        return {
            formData: {
                list: "",
                content: "",
            },
            modalStatus: false,
            selectList: [],
            ids: "",
            resTotal: 0,
            resFee: 0,
        }
    },
    methods: {
        openSelect() {
            this.modalStatus = true
        },
        modalData(status) {
            this.modalStatus = status
        },
        // 选择接收人确认按钮
        messageSend() {
            this.modalStatus = false
            let list = []
            this.ids = []
            if (this.selectList.length != 0) {
                this.selectList.map(item => {
                    list.push(item.title)
                    this.ids.push(item.companyId)
                })
                this.formData.list = list.join(",")
            } else {
                this.formData.list = ""
                this.ids = []
            }
            if (this.ids.length != 0) this.getCount()
            else (this.resTotal = 0), (this.resFee = 0)
        },
        handleSelect(val) {
            this.selectList = val
        },
        getCount() {
            this.$post("/gateway/api/sycompany/pc/company/selectMemberSmsCompany", {
                companyIds: this.ids.join(","),
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res.code == 200) {
                    this.$emit("SmsUser", false)
                    this.resTotal = res.data.sendTotal
                    this.resFee = res.data.fee
                } else {
                    this.$emit("SmsUser", true)
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
    },
    watch: {
        isShow: {
            handler(val) {
                if (val) {
                    ;(this.formData = {
                        list: "",
                        content: "",
                    }),
                        (this.selectList = []),
                        (this.ids = ""),
                        (this.resTotal = 0),
                        (this.resFee = 0)
                    this.$refs.selectRef.sortSelect = []
                    this.$refs.selectRef.tableData = []
                    this.$refs.selectRef.CascaderId = []
                }
            },
        },
    },
    components: {
        LiefengModal,
        SelectMessageUser,
    },
}
</script>

<style scoped lang="less">
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
</style>
