<template>
    <div class="statistics-div">
        <div class="top">
            <div class="left">
                <Menu theme="light" mode="horizontal" :active-name="activeMenu" @on-select="selectMenu">
                    <MenuItem name="1">按行业当前统计</MenuItem>
                    <MenuItem name="2">单位入驻变化统计</MenuItem>
                </Menu>
            </div>
            <div class="right">
                <!-- <Button type="primary" style="margin-right:10px">导出报表信息</Button> -->
                <Button type="primary" @click="backChart">返回可视化统计</Button>
            </div>
        </div>
        <div class="bottom">
            <div class="Table-div" v-if="activeMenu == '1'">
                <Table stripe border :columns="industryColumns" :data="tableData.industryStatisticsRos"></Table>
            </div>
            <div class="Table-div" v-if="activeMenu == '2'">
                <Table stripe border :columns="settledColumns" :data="tableData.settledStatisticsRos"></Table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["showNumber"],
    data() {
        return {
            industryList: [],
            tableData: [],
            activeMenu: "1",
            industryColumns: [
                {
                    title: "行业分类",
                    key: "industryCode",
                    align: "center",
                    minWidth: 80,
                    render: (h, params) => {
                        return h("div", {}, params.row.industryCode == [] ? "" : params.row.industryCode)
                    },
                },
                {
                    title: "开业中已定位",
                    key: "targeted",
                    align: "center",
                    minWidth: 80,
                },
                {
                    title: "开业中数量",
                    key: "settle",
                    align: "center",
                    minWidth: 80,
                },
                {
                    title: "已停业数量",
                    key: "closed",
                    align: "center",
                    minWidth: 80,
                },
                {
                    title: "历史总数量",
                    key: "historicalTotal",
                    align: "center",
                    minWidth: 80,
                },
            ],
            settledColumns: [
                {
                    title: "统计年月",
                    key: "statisticsTime",
                    align: "center",
                    minWidth: 80,
                },
                {
                    title: "新入驻单位数量",
                    key: "settle",
                    align: "center",
                    minWidth: 80,
                },
                {
                    title: "变为停业单位数量",
                    key: "closed",
                    align: "center",
                    minWidth: 80,
                },
                {
                    title: "合计增减单位数量",
                    key: "increaseOrDecrease",
                    align: "center",
                    minWidth: 80,
                },
            ],
        }
    },
    methods: {
        backChart() {
            this.$emit("backChart")
        },
        selectMenu(val) {
            this.activeMenu = val
        },
        // 获取可视化数据的接口
        getChart() {
            this.$get("/gateway/api/sycompany/pc/company/visualizationStatistics").then(res => {
                if (res.code == 200) {
                    this.tableData = res.data
                    if (this.tableData.industryStatisticsRos && this.tableData.industryStatisticsRos.length != 0) {
                        if (this.tableData.industryStatisticsRos.length != 0) {
                            this.tableData.industryStatisticsRos.map(item => {
                                let data = []
                                if (item.industryCode && item.industryCode.length != 0) {
                                    this.industryList.map(items => {
                                        if (item.industryCode.indexOf(items.dictKey) > -1) {
                                            data.push(items.dictValue)
                                        }
                                    })
                                    item.industryCode = data.join(",")
                                }
                            })
                        }
                    }
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        getIndustry(type, model) {
            this.$get("/datamsg/api/common/sys/findDictByDictType", {
                dictType: type,
            }).then(res => {
                if (res.code == 200) {
                    this[model] = res.dataList
                }
            })
        },
    },
    watch: {
        showNumber(val) {
            this.getIndustry("INDUSTRY_TYPE", "industryList")
            this.getChart()
        },
    },
}
</script>

<style lang="less" scoped>
.statistics-div {
    .top {
        display: flex;
        justify-content: space-between;
        height: 60px;
        margin: 10px 0;
        .left {
        }
        .right {
            margin-top: 20px;
        }
    }
    .bottom {
        .Table-div {
            height: 740px;
        }
    }
}
</style>
