<template>
    <LiefengContent :isBack="$route.query.isLineHome" @backClick="$router.push('/residentdatabase')">
        <template v-slot:title>
            辖区单位管理
            <!-- <Button type="error" v-if="$route.query.isLineHome" @click="$router.push('/linehomepage')">返回</Button> -->
        </template>
        <!-- <template v-slot:toolsbarLeft> -->
        <!-- <LiefengTree
    ref="mychild"
    :fixedmenu="true"
    @handleData="getList"
    treeID="mychild"
    fixedContentID="contentarea"
    style="width: 200px"
  ></LiefengTree>
</template>-->
        <template v-slot:toolsbarRight>
            <Form :inline="true" :label-colon="true" class="search">
                <FormItem>
                    <Cascader v-model="CascaderId" :data="CascaderList" :load-data="loadData" change-on-select placeholder="请选择区/街道/社区" style="margin-right: 10px; width: 200px" transfer @on-change="changeCasder"></Cascader>
                </FormItem>
                <FormItem>
                    <Input v-model="search.name" placeholder="请输入单位名称搜索" style="width: 140px;margin-right:10px"></Input>
                </FormItem>
                <FormItem>
                    <Select v-model="search.industryCodeList" :max-tag-count="1" clearable multiple placeholder="请选择行业分类" style="width: 140px;margin-right:10px" transfer>
                        <Option v-for="item in industryList" :key="item.dictKey" :value="item.dictKey">{{ item.dictValue }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select v-model="search.businessStatus" clearable placeholder="请选择营业状态" style="width: 140px;margin-right:10px" transfer>
                        <Option value="">全部</Option>
                        <Option value="1">营业中</Option>
                        <Option value="2">已停业</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <DatePicker v-model="dateTime" placeholder="选择查询时间范围" split-panels style="width: 140px;margin-right:10px" transfer type="daterange" @on-change="changeDate"></DatePicker>
                </FormItem>
                <FormItem>
                    <Select v-model="search.nature" clearable placeholder="请选择单位性质" style="width: 140px;margin-right:10px" transfer>
                        <Option v-for="item in natureList" :key="item.dictKey" :value="item.dictKey">{{ item.dictValue }}</Option>
                    </Select>
                </FormItem>
                <Button icon="ios-search" style="margin-left:10px" type="primary" @click="searchBtn">查询</Button>
                <Button icon="ios-refresh" style="margin-left:10px" type="success" @click="reset">重置</Button>
                <!-- <Button type="primary" style="margin: 0 4px" @click="importExcel" icon="ios-add-circle-outline">导入</Button> -->
                <Button style="margin-left:10px" type="primary" @click="$router.push(`/shopconfirmed?communityCode=${orgCode}&isLineHome=${$route.query.isLineHome}`)">
                    导入并同步
                    <span style="color:red">({{ num }})</span>
                </Button>
                <Button icon="ios-cloud-download-outline" style="margin-left:10px" type="error" @click="exportTable">
                    导出列表数据
                </Button>
                <!-- <Button type="warning" icon="ios-mail" @click="sendMsg">发短信</Button> -->
                <Button style="margin-left:10px" type="primary" @click="openWatch">可视化统计</Button>
                <Button icon="ios-add" style="margin-left:10px" type="primary" @click="addModal">新增</Button>
            </Form>
        </template>
        <template v-slot:contentArea>
            <LiefengTable v-if="showTable" :curPage="page" :fixTable="true" :loading="loading" :page-size="pageSize" :pagesizeOpts="[20, 30, 50, 100]" :tableData="tableData" :talbeColumns="talbeColumns" :total="total" height="200" @hadlePageSize="hadlePageSize"></LiefengTable>

            <!--店铺的modal框-->
            <LiefengModal :fullscreen="true" :title="isAdd == true ? '新增店铺' : isAdd == false ? '修改店铺' : '查看店铺'" :value="messageStatus" @input="messageInput">
                <template v-slot:contentarea>
                    <Formo
                        :companyExpandRo="formData.companyExpandRo"
                        :companyExpandRoFileUrl="companyExpandRoFileUrl"
                        :companyInfoRo="formData.companyInfoRoV2"
                        :companyInfoRoFileUrl="companyInfoRoFileUrl"
                        :formdata="formData"
                        :isShow="showNumber"
                        :isWacth="isWacth"
                        @deleteExpand="deleteExpand"
                        @deleteInfo="deleteInfo"
                        @uploadSuccess="uploadSuccess"
                        @uploadSuccessZhi="uploadSuccessZhi"
                    ></Formo>
                </template>
                <template v-slot:toolsbar>
                    <Button style="margin-right: 10px" type="info" @click="messageInput(false)">取消</Button>
                    <Button v-if="isAdd == null" type="primary" @click="openEditBtn">去修改</Button>
                    <Button :disabled="isAdd == null" :loading="loadingBtn" style="margin: 0 5px 0 13px" type="primary" @click="messageSend">保存</Button>
                </template>
            </LiefengModal>
            <!--停业设置-->
            <LiefengModal :fullscreen="false" :value="tingyeStatus" title="停业设置" @input="tingyeInput">
                <template v-slot:contentarea>
                    <div style="margin:0 20px">
                        <p>确定要将此单位设为停业吗？</p>
                        <div style="margin:20px 0 ">
                            <span slot="label" class="validate">停业时间:</span>
                            <DatePicker v-model="tingyeDate" placeholder="请选择停业时间" style="width: 70%;height:40px;margin-left:20px" type="date"></DatePicker>
                        </div>
                    </div>
                </template>
                <template v-slot:toolsbar>
                    <Button style="margin-right: 10px" type="info" @click="tingyeInput(false)">取消</Button>
                    <Button style="margin: 0 5px 0 13px" type="primary" @click="tingyeSend">保存</Button>
                </template>
            </LiefengModal>
            <!--配置服务跳转-->
            <LiefengModal :fullscreen="false" :value="serviceStatus" height="500px" title="配置服务跳转" width="600px" @input="serviceInput">
                <template v-slot:contentarea>
                    <Service :UploadFileUrl="UploadFileUrl" :formData="serviceFormData" @deleteExpand="deleteServiceExpand" @uploadSuccess="serviceUploadSuccess"></Service>
                </template>
                <template v-slot:toolsbar>
                    <Button style="margin-right: 10px" type="info" @click="serviceInput(false)">取消</Button>
                    <Button style="margin: 0 5px 0 13px" type="primary" @click="serviceSend">保存</Button>
                </template>
            </LiefengModal>
            <!--可视化统计-->
            <LiefengModal :fullscreen="true" :value="chartStatus" title="可视化统计" @input="chartInput">
                <template v-slot:contentarea>
                    <Chart :isShow="showChartNumber"></Chart>
                </template>
                <template v-slot:toolsbar>
                    <Button style="margin-right:10px" type="primary" @click="openChart">统计报表</Button>
                    <Button type="info" @click="chartInput(false)">确定</Button>
                </template>
            </LiefengModal>
            <!--统计报表-->
            <LiefengModal :fullscreen="true" :value="statisticsStatus" title="统计报表" @input="statisticsInput">
                <template v-slot:contentarea>
                    <Statistics :showNumber="showNumberStatistics" @backChart="backChart"></Statistics>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" @click="statisticsInput(false)">确定</Button>
                </template>
            </LiefengModal>
            <!-- 导入 -->
            <LiefengModal :value="excelStatus" class="export-modal" height="200px" title="导入" width="40%" @input="excelModalData">
                <template v-slot:contentarea>
                    <!-- <Form :model="searchForm" :label-width="100">
  <FormItem>
     <span slot="label" class="validate">请选择社区:</span>
     <Tooltip placement="right-start"  style="width:100%">
      <div slot="content" style="width: 200px;white-space: normal">
        由于社区太多，请输入关键字进行刷选
      </div>
      <Select
        v-model="nodeId"
        filterable
        @on-change="getTree"
        placeholder="点击列表/输入搜索"
        :remote-method="loadingCommunityFn"
        :loading="loadingCommunity"
      >
      <Option v-for="(item,index) in nodeList" :value="item.value" :key="index">{{ item.label }}</Option>
      </Select>
  </Tooltip>
  </FormItem>
</Form> -->

                    <LiefengUploadExcel
                        :action="exportAction"
                        :headers="headers"
                        :isShow="showExcel"
                        :showDownModal="{ isShow: true, downLink: './excelfiles/localization.xlsx', downName: '辖区单位导入模板' }"
                        :uploadData="uploadData"
                        style="margin:20px 0"
                        @success="excelSuccess"
                    ></LiefengUploadExcel>
                </template>
            </LiefengModal>

            <!--发短信-->
            <LiefengModal :fullscreen="false" :value="msgModal" height="540px" title="发短信" width="800px" @input="sentMsgModal">
                <template v-slot:contentarea>
                    <Message ref="message" :isShow="showNumber" @SmsUser="SmsUser"></Message>
                </template>
                <template v-slot:toolsbar>
                    <Button style="margin-right: 10px" type="info" @click="sentMsgModal(false)">取消</Button>
                    <Button :disabled="sendDisabled" style="margin: 0 5px 0 13px" type="primary" @click="messageEnter">确定发送</Button>
                </template>
            </LiefengModal>
            <!-- 短信开通提醒界面 -->
            <LiefengModal :value="openTips" height="200px" title="短信开通提醒" width="500px" @input="changeOpenTips">
                <template v-slot:contentarea>
                    <p style="fontSize:16px">{{ orgName }}还未开通短信服务，通过随约系统发送短信，会产生短信费最高0.07元/条，是否提交开通此项服务的申请？如有疑问请联系社区实施专管员。</p>
                </template>
                <template v-slot:toolsbar>
                    <Button :loading="opLoading" style="marginRight:10px" type="primary" @click="goOpen">我要申请开通</Button>
                    <Button @click="notOpen">暂不开通</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengUploadExcel from "./childrens/LiefengUploadExcel.vue"
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengTree from "@/components/LiefengTree"
import Formo from "./childrens/form.vue"
import Service from "./childrens/service.vue"
import Chart from "./childrens/chart.vue"
import Statistics from "./childrens/statistics.vue"
import Message from "./childrens/message.vue"

import Utils from "../jointly/utils/utils"

export default {
    data() {
        return {
            CascaderList: [],
            CascaderId: [],
            communityCode: "",
            num: 0,
            openTips: false,
            orgName: "",
            opLoading: false,

            sendDisabled: false,
            showNumber: 0,

            messageSearchName: "",

            showExcel: 0,
            exportAction: "",
            headers: {
                // 'Content-Type':'application/x-www-form-urlencoded',
                openId: window.sessionStorage.getItem("openId"),
                Authorization: window.sessionStorage.getItem("accessToken"),
            },
            // nodeList: [],
            //异步加载社区
            // loadingCommunity: false,
            // nodeId:'',
            // 导入框
            excelStatus: false,
            // 上传地址
            excelAction: "",
            //上传附带而外参数
            uploadData: {
                // file:,
                orgCode: "",
                impStaff: "",
            },
            //设置上传的请求头
            uploadHeaders: {},
            //默认已上传的文件列表
            fileList: [],
            //excel导入错误信息
            errorContent: null,
            //导入文字显示标识
            textStatus: false,
            //excel文件数据
            excelFile: null,

            //导入地址
            // action:window.vue.getProxy()["/syaa"].target + "/api/syuser/pc/company/importImpUser",

            // 发短信属性

            msgModal: false,
            companyIds: [],
            msgform: {
                // sendChannel: "1",
                // businessCode: "0000", //业务代码 0000:发送系统信息
                // businessType: 5, // 业务类型5:发送系统信息
                // oemCode: parent.vue.oemInfo.oemCode,
                // recIds: "",
                // title: "",
                // content: "",
                // sendId: parent.vue.loginInfo.userinfo.custGlobalId
                staffOrgCode: "", //员工所属机构
                staffName: "", //员工姓名
                businessCode: 3, //业务代码
                oemCode: "",
                smsMsg: "", //短信内容
                companyIds: [], //企业id合集
            },
            ruleValidate: {
                recIds: [
                    {
                        required: true,
                        message: "接收人不能为空",
                        trigger: "blur",
                    },
                ],
                content: [
                    {
                        required: true,
                        message: "内容不能为空",
                        trigger: "blur",
                    },
                ],
            },
            selectName: [],
            resTotal: 0,
            resFee: 0,

            // 选择接收人属性
            selectModal: false,

            // messageMStatus:false,
            // showNumber:0,
            // sendDisabled:false,

            //table表格参数
            talbeColumns: [
                {
                    title: "序号",
                    type: "index",
                    minWidth: 80,
                    align: "center",
                    fixed: "left",
                },
                {
                    title: "编号",
                    key: "companyCode",
                    minWidth: 180,
                    align: "center",
                },
                {
                    title: "所属社区",
                    key: "communityName",
                    minWidth: 180,
                    align: "center",
                },
                {
                    title: "单位名称",
                    key: "name",
                    minWidth: 180,
                    align: "center",
                },
                {
                    title: "单位性质",
                    key: "nature",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {},
                            this.natureList.map(item => {
                                if (item.dictKey == params.row.nature) {
                                    return item.dictValue
                                }
                            })
                        )
                    },
                },
                {
                    title: "行业分类",
                    key: "industryCode",
                    minWidth: 400,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.industryCode ? (params.row.industryCode.length == 0 ? "" : params.row.industryCode) : "")
                    },
                },
                {
                    title: "营业状态",
                    key: "businessStatus",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.businessStatus ? (params.row.businessStatus == 1 ? "营业中" : params.row.businessStatus == 2 ? "已停业" : "") : "")
                    },
                },
                {
                    title: "是否优质",
                    key: "businessStatus",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        return h("i-switch", {
                            props: {
                                value: params.row.highQuality === "1",
                                size: "large",
                            },
                            scopedSlots: {
                                open: () => h("span", "是"),
                                close: () => h("span", "否"),
                            },
                            on: {
                                "on-change": val => {
                                    this.$post(
                                        "/gateway/api/sycompany/pc/company/updateBusinessStatus",
                                        {
                                            companyId: params.row.companyId,
                                            highQuality: val ? "1" : "2",
                                        },
                                        { "Content-Type": "application/json" }
                                    ).then(res => {
                                        if (res.code == 200) {
                                            this.$Message.success({
                                                content: "设置成功",
                                                background: true,
                                            })
                                        }
                                    })
                                },
                            },
                        })
                    },
                },
                {
                    title: "入驻时间",
                    key: "startDate",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.startDate ? this.$core.formatDate(new Date(params.row.startDate), "yyyy-MM-dd") : "")
                    },
                },
                {
                    title: "停业时间",
                    key: "endDate",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.endDate ? this.$core.formatDate(new Date(params.row.endDate), "yyyy-MM-dd") : "")
                    },
                },
                // {
                //     title: "在生活圈的显示状态",
                //     key: "lifeDisplay",
                //     minWidth: 180,
                //     align: "center",
                //     fixed:'right',
                //     render: (h, params) => {
                //         return h("div", [
                //         h("i-switch", {
                //             props: {
                //                 value: params.row.lifeDisplay ? params.row.lifeDisplay : '2',
                //                 size: "large",
                //                 "true-value": "1",
                //                 "false-value": "2",
                //                 "before-change": this.beforeChangeSwitch,
                //             },
                //             scopedSlots: {
                //                 open: () => h("span", "显示"),
                //                 close: () => h("span", "隐藏"),
                //             },
                //             on: {
                //                 "on-change": (val) => {
                //                     this.changeSwitch(val, params.row);
                //                 },
                //             },
                //         }),
                //         ]);
                //     },
                // },
                {
                    title: "操作",
                    slot: "action",
                    width: 100,
                    align: "center",
                    fixed: "right",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Dropdown",
                                {
                                    props: {
                                        transfer: true,
                                    },
                                },
                                [
                                    h(
                                        "Button",
                                        {
                                            props: {
                                                type: "info",
                                                size: "small",
                                                icon: "ios-arrow-down",
                                            },
                                        },
                                        "编辑"
                                    ),
                                    h(
                                        "DropdownMenu",
                                        {
                                            slot: "list",
                                        },
                                        [
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            this.isAdd = null
                                                            this.isWacth = true
                                                            this.companyId = params.row.companyId
                                                            this.getDetail(params.row.companyId)
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                },
                                                "查看"
                                            ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            this.isAdd = false
                                                            this.isWacth = false
                                                            this.companyId = params.row.companyId
                                                            this.getDetail(params.row.companyId)
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                },
                                                "修改"
                                            ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            if (params.row.businessStatus == "1") {
                                                                this.tingyeRow = {}
                                                                this.tingyeRow = params.row
                                                                this.tingyeStatus = true
                                                                this.tingyeDate = ""
                                                            } else {
                                                                this.$Modal.confirm({
                                                                    title: "温馨提示",
                                                                    content: "<p>是否设为开业</p>",
                                                                    onOk: () => {
                                                                        let paramss = {
                                                                            companyId: params.row.companyId,
                                                                            endTime: "",
                                                                        }
                                                                        this.$post("/gateway/api/sycompany/pc/company/updateBusinessStatus", paramss, { "Content-Type": "application/json" })
                                                                            .then(res => {
                                                                                if (res.code == 200) {
                                                                                    this.$Message.success({
                                                                                        background: true,
                                                                                        content: "操作成功",
                                                                                    })
                                                                                    this.page = this.currentPage
                                                                                    this.getList()
                                                                                } else {
                                                                                    this.$Message.error({
                                                                                        background: true,
                                                                                        content: res.desc,
                                                                                    })
                                                                                    this.showTable = false
                                                                                    this.$nextTick(() => {
                                                                                        this.showTable = true
                                                                                    })
                                                                                    this.page = this.currentPage
                                                                                    this.getList()
                                                                                }
                                                                            })
                                                                            .catch(err => {
                                                                                this.$Message.error({
                                                                                    background: true,
                                                                                    content: "操作失败",
                                                                                })
                                                                            })
                                                                    },
                                                                })
                                                            }
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                },
                                                params.row.businessStatus == "1" ? "设为停业" : "设为开业"
                                            ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            this.companyId = ""
                                                            this.companyId = params.row.companyId
                                                            this.getServiceDetail(params.row.companyId)
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                },
                                                "配置服务跳转"
                                            ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            this.$Modal.confirm({
                                                                title: "温馨提示",
                                                                content: "您正在删除当前数据，是否继续？",
                                                                onOk: () => {
                                                                    this.deleteRow(params.row.companyId)
                                                                },
                                                            })
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                },
                                                "删除"
                                            ),
                                        ]
                                    ),
                                ]
                            ),
                        ])
                    },
                },
            ],
            tableData: [],
            loading: false,
            page: 1,
            pageSize: 20,
            total: 0,
            currentPage: 0,
            // 搜索内容集合
            search: {
                name: "",
                nature: "",
                industryCodeList: [],
                businessStatus: "", //营业状态
                startDate: "",
                endDate: "",
            },
            dateTime: [],
            // 行业类型
            industryList: [],
            natureList: [],
            isAdd: false,

            // 模态框控制
            messageStatus: false,
            loadingBtn: false, //模态框确认按钮

            // 组件数据
            formData: {
                companyInfoRoV2: {},
                companyExpandRo: {},
            },
            showNumber: 0,

            // 上传组件的list
            companyInfoRoFileUrl: [],
            companyExpandRoFileUrl: [],

            // 显示table表的显示隐藏
            showTable: true,

            // 是否点击了查看
            isWacth: false,

            // 停业设置
            tingyeStatus: false,
            tingyeDate: "",
            tingyeRow: {},

            // 存储的店铺id
            companyId: "",

            // 服务配置跳转状态
            serviceStatus: false,
            UploadFileUrl: [],
            serviceFormData: {},

            // 可视化统计模态框
            chartStatus: false,
            showChartNumber: 0,

            // 统计报表的模态框
            statisticsStatus: false,
            showNumberStatistics: 0,

            //菜单树社区编码
            orgCode: "",
            orgName: "",
            isNum: 0,
            searchLenght: 3,
        }
    },
    async created() {
        let orgCode = this.$core.getUserInfo().orgCode
        console.log(orgCode)
        this.uploadData.orgCode = orgCode
        this.msgform.staffOrgCode = orgCode
        this.msgform.staffName = this.$core.getUserInfo().nickName
        this.msgform.oemCode = this.$core.getUserInfo().oemCode

        await this.getIndustry("INDUSTRY_TYPE", "industryList")
        await this.getIndustry("UNIT_NATURE", "natureList")
        await this.getCascader()
        await this.getList()
    },
    methods: {
        // 弹窗确认按钮
        messageEnter() {
            if (this.$refs.message.selectList.length == 0) {
                this.$Message.warning({
                    content: "请选择接收人",
                    background: true,
                })
                return
            }
            if (this.$refs.message.formData.content == "") {
                this.$Message.warning({
                    content: "请填写短信内容",
                    background: true,
                })
                return
            }
            let data = []
            this.$refs.message.selectList.map(item => {
                data.push(item.companyId)
            })
            console.log(data)
            this.sendRow(data)
        },
        sendRow(data) {
            this.$post("/gateway/api/sycompany/pc/company/sendMessages", {
                staffOrgCode: this.$core.getUserInfo().orgCode,
                staffName: this.$core.getUserInfo().realName,
                businessCode: "3",
                oemCode: this.$core.getUserInfo().oemCode,
                smsMsg: this.$refs.message.formData.content,
                companyIds: data.join(),
            }).then(res => {
                if (res.code == 200) {
                    this.msgModal = false
                    this.$Message.success({
                        content: "短信发送成功",
                        background: true,
                    })
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 弹窗返回按钮
        messageBack() {
            this.messageStatus = false
        },
        // 发信息
        SmsUser(status) {
            this.sendDisabled = status
        },
        messageSearchBtn() {},
        //   getSelect(){
        //    this.$get("/old/api/pc/project/selectStaffManagerCommunity", {
        //       staffId: parent.vue.loginInfo.userinfo.id,
        //       orgCode:parent.vue.loginInfo.userinfo.orgCode
        //     })
        //       .then((res) => {
        //         if (res.code === "200"&&res.dataList&&res.dataList.length>0) {
        //           // this.getTree(this.communityCode);   //第一次进来默认获取第一个社区数据
        //           this.nodeList = res.dataList.map((item) => {
        //             return {
        //               value: item.code,
        //               label: item.fullName,
        //               projectCode: item.projectCode
        //             };
        //           });
        //           this.allNodeList = JSON.parse(JSON.stringify(this.nodeList));
        //           if(this.nodeList.length > 50) {
        //             this.nodeList.length = 50;
        //           }
        //         }
        //       })
        //       .catch((err) => {
        //         console.log(err);
        //       });
        // },
        //异步加载社区
        // loadingCommunityFn(query) {
        //    if(query !== '' && !Number(query)) {
        //     this.loadingCommunity = true;
        //    this.nodeList = this.allNodeList.filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
        //    if(this.nodeList.length > 50) this.nodeList.length = 50;
        //     this.loadingCommunity = false;
        //   } else if(Number(query)) {
        //     return;
        //   }
        //   else {
        //     this.nodeList = [];
        //   }
        // },
        // getTree(val){
        //     this.nodeId = val
        //     // this.uploadData.orgCode = this.nodeId
        // },
        //修改excel导入弹窗状态
        excelModalData(status) {
            this.excelStatus = status
            // this.showExcel = false
        },

        // exportModalFn(status){
        //     this.excelStatus = status
        // },
        // 导入
        importExcel() {
            // this.nodeId = ''
            // this.uploadData.orgCode = ''
            this.uploadData.impStaff = this.$core.getUserInfo().nickName
            this.excelStatus = true
            this.showExcel = this.showExcel + 1
            this.exportAction = window.vue.getProxy()["/syaa"].target + "/upload/sycompany/pc/company/importImpCompany"
            // this.exportAction =  "syaa/api/syuser/pc/company/importImpUser";
            console.log("true")
        },
        // 导入成功回调事件
        excelSuccess() {
            this.excelStatus = false
            //   this.hadlePageSize({
            //     page: this.page,
            //     pageSize: this.pageSize
            //   });
        },
        //上传之前
        beforeExcelUpload() {},
        //上传成功
        myExcel() {},
        // 文件格式验证失败的
        uploadFormatError(file) {
            this.$Notice.warning({
                title: "文件格式不正确",
                desc: "文件" + file.name + "格式不正确，请上传xls,xlsx,xlsb,xlsm或xlst格式的文件",
            })
        },
        //文件超出指定大小
        uploadSizeError(file) {
            this.$Notice.error({
                title: "导入失败",
                desc: "文件 " + file.name + " 大小已超出限制，请控制在10M以内",
            })
        },
        //模板下载
        dowunModal() {},
        // 确认导入
        submitExcel() {},

        // 发短信方法

        sendMsg() {
            // 验证机构是否开通短信功能
            this.$get("/syaa/pc/sy/sms/checkSmsChannel", {
                orgCode: this.$core.getUserInfo().orgCode,
            }).then(res => {
                if (res.code == 200) {
                    this.sentMsgModal(true)
                }
                if (res.code == 500) {
                    this.changeOpenTips(true)
                }
            })
        },
        changeOpenTips(status) {
            this.openTips = status
        },
        notOpen() {
            this.changeOpenTips(false)
        },
        sentMsgModal(status) {
            this.msgModal = status
        },
        goOpen() {
            this.opLoading = true
            this.$post(
                "/syaa/pc/sy/smsChannel/submitChannel",
                {
                    orgCode: this.$core.getUserInfo().orgCode,
                    custGlobalId: this.$core.getUserInfo().custGlobalId,
                    channelId: "1",
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "申请发送成功",
                        background: true,
                    })
                } else {
                    this.$Message.warning({
                        content: res.desc,
                        background: true,
                    })
                }
            })
            this.opLoading = false
            this.changeOpenTips(false)
        },

        //   返回可视化统计按钮
        backChart() {
            this.statisticsStatus = false
        },
        //   关闭统计报表事件
        statisticsInput(status) {
            this.statisticsStatus = status
        },
        //   点击统计报表按钮
        openChart() {
            this.statisticsStatus = true
            ++this.showNumberStatistics
        },
        chartInput(status) {
            this.chartStatus = status
        },
        // 点击可视化统计
        openWatch() {
            this.chartStatus = true
            ++this.showChartNumber
        },

        // 配置服务删除图片按钮
        deleteServiceExpand() {
            this.serviceFormData.fileUrl = ""
            this.UploadFileUrl = []
        },
        // 配置服务上传成功回调事件
        serviceUploadSuccess(val) {
            this.serviceFormData.fileUrl = val.url
            this.UploadFileUrl = []
            this.UploadFileUrl.push({ url: val.url })
        },
        // 配置服务跳转模态框关闭事件
        serviceInput(status) {
            this.serviceStatus = status
        },
        // 配置服务跳转确定按钮
        serviceSend() {
            if (this.serviceFormData) {
                if (!this.serviceFormData.linkType || this.serviceFormData.linkType == "") {
                    this.$Message.warning({
                        content: "请选择关联类型",
                        background: true,
                    })
                    return
                }
                if (!this.serviceFormData.linkUrl || this.serviceFormData.linkUrl == "") {
                    this.$Message.warning({
                        content: "请填写跳转链接",
                        background: true,
                    })
                    return
                } else {
                    let reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/
                    if (!reg.test(this.serviceFormData.linkUrl)) {
                        this.$Message.warning({
                            content: "请填写正确的跳转链接",
                            background: true,
                        })
                        return
                    }
                }
                if (this.serviceFormData.businessType && this.serviceFormData.businessType != "") {
                    if (this.UploadFileUrl.length == 0) {
                        this.$Message.warning({
                            content: "请上传背景",
                            background: true,
                        })
                        return
                    }
                }
                if (this.UploadFileUrl.length != 0) {
                    if (!this.serviceFormData.businessType || this.serviceFormData.businessType == "") {
                        this.$Message.warning({
                            content: "请选择背景类型",
                            background: true,
                        })
                        return
                    }
                }
                this.updateService(this.serviceFormData)
            }
        },
        // 点击去修改按钮
        openEditBtn() {
            this.isAdd = false
            this.isWacth = false
            this.messageStatus = false
            this.getDetail(this.companyId)
        },
        tingyeInput(status) {
            this.tingyeStatus = status
        },
        // 停业确定按钮
        tingyeSend() {
            if (this.tingyeDate == "") {
                this.$Message.warning({
                    content: "停业时间不能为空",
                    background: true,
                })
                return
            }
            let params = {
                companyId: this.tingyeRow.companyId,
                endDate: this.$core.formatDate(new Date(this.tingyeDate), "yyyy-MM-dd"),
                // lifeDisplay:2
            }
            this.$post("/gateway/api/sycompany/pc/company/updateBusinessStatus", params, { "Content-Type": "application/json" })
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            background: true,
                            content: "操作成功",
                        })
                        this.tingyeStatus = false
                        this.page = this.currentPage
                        this.getList()
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                        this.showTable = false
                        this.$nextTick(() => {
                            this.showTable = true
                        })
                        this.page = this.currentPage
                        this.getList()
                    }
                })
                .catch(err => {
                    this.$Message.error({
                        background: true,
                        content: "操作失败",
                    })
                })
        },
        //   修改状态前
        beforeChangeSwitch() {
            return new Promise(resolve => {
                this.$Modal.confirm({
                    title: "切换确认",
                    content: "您确认要切换状态吗？",
                    onOk: () => {
                        resolve()
                    },
                })
            })
        },
        //   切换状态
        changeSwitch(val, data) {
            let params = {
                companyId: data.companyId,
                // endDate:data.endDate ? this.$core.formatDate(new Date(data.endDate),"yyyy-MM-dd") : '',
                lifeDisplay: val,
            }
            this.$post("/gateway/api/sycompany/pc/company/updateBusinessStatus", params, { "Content-Type": "application/json" })
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            background: true,
                            content: "操作成功",
                        })
                        this.page = this.currentPage
                        this.getList()
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                        this.showTable = false
                        this.$nextTick(() => {
                            this.showTable = true
                        })
                        this.page = this.currentPage
                        this.getList()
                    }
                })
                .catch(err => {
                    this.$Message.error({
                        background: true,
                        content: "操作失败",
                    })
                })
            // this.updateCompany(params)
        },
        //   删除封面
        deleteInfo() {
            this.companyInfoRoFileUrl = []
        },
        //   删除营业执照
        deleteExpand() {
            this.companyExpandRoFileUrl = []
        },
        //   上传营业执照
        uploadSuccessZhi(val) {
            this.companyExpandRoFileUrl = []
            this.companyExpandRoFileUrl.push({ url: val.url })
        },
        //   上传封面图片回调事件
        uploadSuccess(val) {
            this.companyInfoRoFileUrl = []
            this.companyInfoRoFileUrl.push({ url: val.url })
        },
        // 模态框确定按钮
        messageSend() {
            let data = JSON.parse(JSON.stringify(this.formData))

            // 判断基本信息
            // if (!data.name || data.name == "") {
            //     this.$Message.warning({
            //         content: "请填写基本信息-单位名称",
            //         background: true,
            //     })
            //     return
            // }
            // if (!data.community || data.community.length == 0) {
            //     this.$Message.warning({
            //         content: "请选择基本信息-所属社区",
            //         background: true,
            //     })
            //     return
            // } else {
            //     if (data.community.length != 0 && data.community.length != 3) {
            //         this.$Message.warning({
            //             content: "基本信息-所属社区请选择到社区",
            //             background: true,
            //         })
            //         return
            //     }
            //     data.companyInfoRoV2.zoneCode = data.companyInfoRoV2.community[0]
            //     data.companyInfoRoV2.streetCode = data.companyInfoRoV2.community[1]
            //     data.companyInfoRoV2.communityCode = data.companyInfoRoV2.community[2]
            // }

            // if (!data.companyInfoRoV2.addr || data.companyInfoRoV2.addr == "") {
            //     this.$Message.warning({
            //         content: "请填写基本信息-联系地址",
            //         background: true,
            //     })
            //     return
            // }
            // let iphoneNum = /^[1][3,4,5,7,8,9][0-9]{9}$/
            // if (data.companyInfoRoV2.mobile && !iphoneNum.test(data.companyInfoRoV2.mobile)) {
            //     this.$Message.warning({
            //         content: "基本信息-请填写正确的联系电话",
            //         background: true,
            //     })
            //     return
            // }
            // let idNum = /^[a-zA-Z]{1}[-_a-zA-Z0-9]{5,19}$/
            // if (data.companyInfoRoV2.wechat && !idNum.test(data.companyInfoRoV2.wechat)) {
            //     this.$Message.warning({
            //         content: "基本信息-请填写正确的微信号",
            //         background: true,
            //     })
            //     return
            // }
            // if (data.companyInfoRoV2.longitude && data.companyInfoRoV2.longitude != "") {
            //     let list = []
            //     list = data.companyInfoRoV2.longitude.split(",")
            //     if (list.length != 2) {
            //         this.$Message.warning({
            //             content: "基本信息-请填写正确的经纬度，经纬度用逗号隔开",
            //             background: true,
            //         })
            //         return
            //     } else {
            //         data.companyInfoRoV2.longitude = list[0]
            //         data.companyInfoRoV2.latitude = list[1]
            //     }
            // }
            // if (data.companyInfoRoV2.chargeName && data.companyInfoRoV2.chargeName != "") {
            //     data.companyInfoRoV2.chargeType = 2
            // }
            // if (data.companyInfoRoV2.mobile && data.companyInfoRoV2.mobile != "") {
            //     data.companyInfoRoV2.chargeType = 2
            // }
            // if (data.companyInfoRoV2.wechat && data.companyInfoRoV2.wechat != "") {
            //     data.companyInfoRoV2.chargeType = 2
            // }
            // delete data.companyInfoRoV2.orgCode

            // if (data.companyInfoRoV2.startDate && data.companyInfoRoV2.startDate != "") {
            //     data.companyInfoRoV2.startDate = this.$core.formatDate(new Date(data.companyInfoRoV2.startDate), "yyyy-MM-dd")
            // } else {
            //     delete data.companyInfoRoV2.startDate
            // }
            // if (data.companyInfoRoV2.chargeName && data.companyInfoRoV2.chargeName != "") {
            //     data.companyInfoRoV2.chargeType = 1
            // }
            // if (data.companyInfoRoV2.businessStatus && data.companyInfoRoV2.businessStatus == "2") {
            //     if (data.companyInfoRoV2.endDate == "" || !data.companyInfoRoV2.endDate) {
            //         this.$Message.warning({
            //             content: "请填写扩展信息-停业时间",
            //             background: true,
            //         })
            //         return
            //     } else {
            //         data.companyInfoRoV2.endDate = this.$core.formatDate(new Date(data.companyInfoRoV2.endDate), "yyyy-MM-dd")
            //     }
            // } else {
            //     if (data.companyInfoRoV2.endDate && data.companyInfoRoV2.endDate == "") {
            //         delete data.companyInfoRoV2.endDate
            //     }
            // }
            // if (data.companyInfoRoV2.mobile && data.companyInfoRoV2.mobile != "") {
            //     data.companyInfoRoV2.chargeType = 1
            // }
            // // let areaNum = /^[1-9]*[1-9][0-9]*$/
            // if (data.companyInfoRoV2.businessArea) {
            //     if (data.companyInfoRoV2.businessArea > 0) {
            //     } else {
            //         this.$Message.warning({
            //             content: "扩展信息-请填写正确的经营或办公面积",
            //             background: true,
            //         })
            //         return
            //     }
            // }
            // if (data.companyInfoRoV2.idNum && data.companyInfoRoV2.idNum != "") {
            //     if (this.testid(data.companyInfoRoV2.idNum) == false) {
            //         this.$Message.warning({
            //             content: "扩展信息-请填写正确的身份证号",
            //             background: true,
            //         })
            //         return
            //     } else {
            //         data.companyInfoRoV2.chargeType = 1
            //     }
            // }

            // if (!data.companyFileVos) {
            //     data.companyFileVos = []
            // }

            // // 封面图片
            // if (this.companyInfoRoFileUrl?.length > 0) {
            //     data.companyFileVos.push({
            //         businessType: 2,
            //         fileUrl: this.companyInfoRoFileUrl[0].url,
            //     })
            // }

            // // 营业执照图片
            // if (this.companyExpandRoFileUrl?.length > 0) {
            //     data.companyFileVos.push({
            //         businessType: 1,
            //         fileUrl: this.companyExpandRoFileUrl[0].url,
            //     })
            // }

            // // 个人信息
            // data.companyChargeVos = [
            //     {
            //         chargeType: "1",
            //         idNum: iol && this.shopStatus == "1" ? data.idNum : null,
            //         mobile: data.mobile,
            //         name: data.principal,
            //     },
            // ]

            this.createCompany(this.onSubmitBefore(data))
        },

        /**
         * 提交表单的前置事件
         */
        onSubmitBefore(data) {
            const res = {
                oemCode: this.$core.getUserInfo().oemCode,
                companyId: this.companyId,
            }

            res.companyInfoRoV2 = {
                startDate: new Date(),
                businessStatus: "1",
                shopStatus: this.shopStatus,
                remark: data.remark,
            }

            Utils.copyObjcetByKeys(res.companyInfoRoV2, data, "name", "addr", "nature", "zoneCode", "streetCode", "communityCode")

            // 个人信息
            res.companyChargeVos = [
                {
                    chargeType: "1",
                    idNum: data.chargeIdNum,
                    mobile: data.chargeMobile,
                    name: data.chargeName,
                },
                {
                    chargeType: "2",
                    idNum: data.idNum,
                    mobile: data.mobile,
                    name: data.principal,
                    wechat: data.wechat,
                },
            ]

            // 文件资源
            var fs = []

            // 封面图片
            if (data.cover_image) {
                fs.push({
                    businessType: 2,
                    fileId: data.cover_image.fileId,
                    fileUrl: data.cover_image.url,
                })
            }

            // 拷贝对象值
            Utils.copyObjcetByKeys(res.companyInfoRoV2, data, "name", "fullName", "industryCode", "staffAmount", "businessArea", "addr", "nature", "remark", "appointmentStatus", "zoneCode", "streetCode", "communityCode", "startDate", "businessStatus", "longitude", "latitude")

            res.companyInfoRoV2.shopStatus = this.shopStatus

            // 是否申请商家
            res.companyArchivesRo = {
                email: data.email,
            }

            // 上传图片
            data.business_license &&
                fs.push({
                    businessType: 1,
                    fileId: data.business_license.fileId,
                    fileUrl: data.business_license.url,
                })
            data.id_card_handheld &&
                fs.push({
                    businessType: 5,
                    fileId: data.id_card_handheld.fileId,
                    fileUrl: data.id_card_handheld.url,
                })
            data.id_card_front &&
                fs.push({
                    businessType: 6,
                    fileId: data.id_card_front.fileId,
                    fileUrl: data.id_card_front.url,
                })
            data.id_card_reverse &&
                fs.push({
                    businessType: 7,
                    fileId: data.id_card_reverse.fileId,
                    fileUrl: data.id_card_reverse.url,
                })

            Utils.copyObjcetByKeys(res.companyArchivesRo, data, "bankAdminName", "bankAccount", "bankBranchName", "bankBranchNum")

            res.companyFileVos = fs

            return res
        },

        // 模态框关闭事件
        messageInput(status) {
            this.messageStatus = status
        },
        // 改变时间事件
        changeDate(val) {
            this.search.startDate = val[0]
            this.search.endDate = val[1]
        },
        // 改变分页
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 查询按钮
        searchBtn() {
            this.page = 1
            if (this.searchLenght == 3) {
                this.search.communityCode = this.orgCode
                this.getList()
            } else {
                this.$Message.warning({
                    content: "请选择到社区进行查询",
                    background: true,
                })
            }
        },
        // 重置按钮
        reset() {
            this.page = 1
            this.search = {
                name: "",
                nature: "",
                industryCodeList: [],
                businessStatus: "",
                startDate: "",
                endDate: "",
                communityCode: "",
            }
            this.dateTime = []
            this.getList()
        },
        // 点击新增按钮
        addModal() {
            this.companyId = null
            this.isAdd = true
            this.isWacth = false
            this.messageStatus = true
            ;(this.formData = {
                companyInfoRo: {},
                companyExpandRo: {},
            }),
                (this.companyInfoRoFileUrl = []),
                (this.companyExpandRoFileUrl = [])
            ++this.showNumber
        },

        // 接口部分
        // 获取分页数据
        async getList(data) {
            //    this.communityCode = data.communityCode
            this.$get(`/gateway/api/sycompany/pc/company/selectStatusCount`, {
                // orgCode: this.$core.getUserInfo().orgCode,
                communityCode: this.$core.getUserInfo().orgCode,
                syncStatus: 1,
            }).then(res => {
                if (res.code == 200) {
                    this.num = res.data
                }
            })
            this.loading = true
            await this.$get("/gateway/api/sycompany/pc/company/getCompanyByPage", {
                page: this.page,
                pageSize: this.pageSize,
                industryCodeList: this.search.industryCodeList.join(","),
                orgCode: this.$core.getUserInfo().orgCode,
                ...this.search,
            }).then(res => {
                this.loading = false
                if (res.code == 200) {
                    if (res.dataList && res.dataList.length != 0) {
                        res.dataList.map(item => {
                            if (item.industryCode && item.industryCode != "") {
                                let data = []
                                this.industryList.map(items => {
                                    if (item.industryCode.indexOf(items.dictKey) > -1) {
                                        data.push(items.dictValue)
                                    }
                                })
                                item.industryCode = data.join(",")
                            }
                        })
                    }
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    this.currentPage = res.currentPage
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 删除数据接口
        deleteRow(companyId) {
            this.$post("/gateway/api/sycompany/pc/company/deleteCompany", {
                companyId,
            }).then(res => {
                if (res.code == 200) {
                    this.page = this.currentPage
                    this.getList()
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 新增以及修改的接口
        createCompany(data) {
            this.$post(
                "/gateway/api/sycompany/pc/company/saveCompany",
                {
                    ...data,
                    orgCode: this.$core.getUserInfo().orgCode,
                },
                { "Content-Type": "Application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.isAdd = false
                    this.messageStatus = false
                    ;(this.formData = {
                        companyInfoRo: {},
                        companyExpandRo: {},
                    }),
                        (this.companyInfoRoFileUrl = []),
                        (this.companyExpandRoFileUrl = [])
                    if (this.isAdd) {
                        this.page = 1
                    } else {
                        this.page = this.currentPage
                    }
                    this.isWacth = false
                    this.getList()
                    // this.$refs.selchildren.getList()
                } else {
                    this.isWacth = false
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 更新状态接口
        updateService(params) {
            this.$post(
                "/gateway/api/sycompany/pc/company/configService",
                {
                    ...params,
                    companyId: this.companyId,
                },
                { "Context-Type": "Application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "设置配置服务跳转成功",
                        background: true,
                    })
                    this.serviceStatus = false
                    this.UploadFileUrl = []
                    this.page = this.currentPage
                    this.getList()
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 获取详情接口
        getDetail(companyId) {
            this.$Message.loading({
                content: "正在加载店铺详情...",
                duration: 0,
            })
            this.$get("/gateway/api/sycompany/pc/company/getCompanyInfoById", {
                companyId,
            }).then(res => {
                this.$Message.destroy()
                if (res.code == 200) {
                    ;(this.formData = {}), (this.companyInfoRoFileUrl = []), (this.companyExpandRoFileUrl = [])
                    ++this.showNumber
                    this.formData = this.processData(res.data)

                    this.messageStatus = true
                } else {
                    this.$Message.error({
                        content: "获取店铺详情失败",
                        background: true,
                    })
                    return
                }
            })
        },

        /**
         * 处理数据
         */
        processData(data) {
            var res = {}

            for (let i = 0, fs = data?.companyFileVos; i < fs?.length; i++) {
                const f = fs[i]

                if (!f || !f.fileUrl) {
                    continue
                }

                f.url = f.fileUrl

                switch (f.businessType) {
                    case "1": // 营业执照
                        res.business_license = f
                        break
                    case "2": // 封面图片
                        res.cover_image = f
                        break
                    case "5": // 手持身份证照
                        res.id_card_handheld = f
                        break
                    case "6": // 身份证正面
                        res.id_card_front = f
                        break
                    case "7": // 身份证反面
                        res.id_card_reverse = f
                        break
                    case "8": // 介绍图片
                        if (!res.introduction_image) {
                            res.introduction_image = []
                        }
                        res.introduction_image.push(f)
                }
            }

            // 营业时间
            res.businessTimeRos = data.businessTimeRos
                ? Utils.each(data.businessTimeRos, e => {
                      // 拆分周规则的数组
                      e.rule = e.rule.split(",")
                  })
                : []

            this.shopStatus = data.companyInfoRoV2.shopStatus

            // 拷贝对象
            Utils.copyObjcetByKeys(res, data.companyInfoRoV2)

            if (data.companyInfoRoV2.startDate) {
                res.startDate = new Date(data.companyInfoRoV2.startDate)
            }

            Utils.copyObjcetByKeys(res, data.companyArchivesRo)

            // 写入个人信息
            Utils.each(data.companyChargeVos, v => {
                switch (v.chargeType) {
                    case "1": // 法人/负责人
                        res.chargeName = v.name
                        res.chargeMobile = v.mobile
                        res.chargeIdNum = v.idNum
                        break
                    case "2": // 生产管理人
                        Utils.copyObjcetByKeys(res, v, "mobile", "idNum", "wechat")
                        res.principal = v.name
                        break
                }
            })

            // 标签
            const codes = [],
                ids = [],
                names = []

            // 处理标签数据
            Utils.each(data.labelVoList, v => {
                if (!v) return

                if (v.labelType === "1") {
                    res.type = v.id
                } else if (v.labelType === "2") {
                    codes.push(v.labelCode)
                    ids.push(v.id)
                    names.push(v.labelName)
                }
            })

            // 标签
            this.labelCodes = codes
            res.companyLabels = ids
            res.companyLabelNames = names.join(", ")

            // 处理社区所属
            res.community = []

            Utils.each(["zoneCode", "streetCode", "communityCode"], k => {
                if (!data.companyInfoRoV2[k]) {
                    return "break"
                }
                res.community.push(data.companyInfoRoV2[k])
            })

            return res
        },

        getFile(data, type) {
            if (!data.companyFileVos) return null

            for (let i = 0, l = data.companyFileVos; i < l.length; i++) {
                let e = l[i]

                if (e.businessType == type) {
                    return e
                }
            }

            return null
        },

        // 获取配置服务详情
        getServiceDetail(companyId) {
            this.$get("/gateway/api/sycompany/pc/company/getConfigServiceInfo", {
                companyId,
            }).then(res => {
                if (res.code == 200) {
                    this.serviceFormData = res.data
                    this.serviceStatus = true
                    this.UploadFileUrl = []
                    if (res.data.fileUrl && res.data.fileUrl != "") {
                        this.UploadFileUrl.push({ url: res.data.fileUrl })
                    }
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 获取行业分类接口
        async getIndustry(type, model) {
            await this.$get("/datamsg/api/common/sys/findDictByDictType", {
                dictType: type,
            }).then(res => {
                if (res.code == 200) {
                    this[model] = res.dataList
                }
            })
        },
        //  导出当前数据列表按钮接口
        exportTable() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "确认导出当前列表信息",
                onOk: () => {
                    this.$post(
                        "/gateway/api/sycompany/pc/company/exportCompany",
                        {
                            page: this.page,
                            pageSize: this.pageSize,
                            ...this.search,
                            industryCodeList: this.search.industryCodeList,
                        },
                        { "Content-Type": "application/json" }
                    ).then(res => {
                        if (res.code == 200) {
                            if (res.data) {
                                if (res.data.slice(0, 4) == "http" && res.data.slice(0, 5) !== "https") {
                                    res.data = res.data.replace("http", "https")
                                }
                            }
                            let link = document.createElement("a")
                            link.href = res.data
                            document.body.appendChild(link)
                            link.click()
                            link.download = "导出信息"
                            document.body.removeChild(link)
                            this.$Message.destroy()
                        } else {
                            this.$Message.warning({
                                content: res.desc,
                                background: true,
                            })
                        }
                    })
                },
            })
        },
        //  提供的公共方法
        // 校验身份证
        testid(id) {
            // 1 "验证通过!", 0 //校验不通过 // id为身份证号码
            var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/
            //号码规则校验
            if (!format.test(id)) {
                return false
            }
            //区位码校验
            //出生年月日校验  前正则限制起始年份为1900;
            var year = id.substr(6, 4), //身份证年
                month = id.substr(10, 2), //身份证月
                date = id.substr(12, 2), //身份证日
                time = Date.parse(month + "-" + date + "-" + year), //身份证日期时间戳date
                now_time = Date.parse(new Date()), //当前时间戳
                dates = new Date(year, month, 0).getDate() //身份证当月天数
            if (time > now_time || date > dates) {
                return false
            }
            //校验码判断
            var c = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2) //系数
            var b = new Array("1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2") //校验码对照表
            var id_array = id.split("")
            var sum = 0
            for (var k = 0; k < 17; k++) {
                sum += parseInt(id_array[k]) * parseInt(c[k])
            }
            if (id_array[17].toUpperCase() != b[sum % 11].toUpperCase()) {
                return false
            }
            return true
        },

        changeCasder(val, item) {
            if (item.length == 1) {
                // this.$Message.warning({
                //   content:'请选择到社区进行查询',
                //   background:true
                // })
                // return
                this.searchLenght = 1
            } else if (item.length == 2) {
                //    this.$Message.warning({
                //       content:'请选择到社区进行查询',
                //       background:true
                //     })
                //     return
                this.searchLenght = 2
            } else if (item.length == 3) {
                this.searchLenght = 3
                this.orgCode = item[2].orgCode
                this.orgName = item[2].orgName
            }
        },
        loadData(item, callback) {
            item.loading = true
            let params = {
                custGlobalId: this.$core.getUserInfo().custGlobalId,
                oemCode: this.$core.getUserInfo().oemCode,
                orgCode: item.orgCode,
                orgCodeSelf: this.$core.getUserInfo().orgCode,
            }
            this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                ...params,
            }).then(res => {
                if (res.code == 200 && res.dataList.length != 0) {
                    let arr = this.forMatterTree(res.dataList)
                    arr.map(items => {
                        if (items.orgLevel == 4) {
                            items.loading = false
                        }
                    })
                    item.loading = false
                    item.children = arr
                    callback(item.children)
                    if (this.isNum == 0 && item.children.length != 0) {
                        this.isNum = 1
                        return
                    } else if (this.isNum == 1 && item.children.length != 0) {
                        this.isNum = 0
                        this.orgCode = item.children[0].value
                        // this.hadlePageSize({
                        //     pageSize: this.pageSize,
                        //     page: this.page,
                        //     //菜单树社区编码
                        //     orgCode: this.orgCode,
                        // })
                    }
                } else {
                    callback([])
                }
                console.log(this.CascaderList)
            })
        },
        forMatterTree(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].label = data[i].orgName
                data[i].value = data[i].orgCode
                if (data[i].children && data[i].children != null && data[i].children.length > 0) {
                    this.forMatterTree(data[i].children)
                }
            }
            return data
        },
        getCascader() {
            this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                custGlobalId: this.$core.getUserInfo().custGlobalId,
                oemCode: this.$core.getUserInfo().oemCode,
                orgCode: "4401",
                orgCodeSelf: this.$core.getUserInfo().orgCode,
            }).then(res => {
                if (res.code == 200 && res.dataList.length != 0) {
                    this.CascaderList = this.forMatterTree(res.dataList)
                    this.CascaderList.map(item => {
                        item.loading = false
                    })
                    // if (this.isNum == 0 && this.CascaderList.length != 0) {
                    //     this.CascaderId.push(this.CascaderList[0].value)
                    // }
                    // this.getList({
                    //     // pageSize: this.pageSize,
                    //     // page: this.pageNum,
                    // })
                }
            })
        },
    },
    components: {
        LiefengUploadExcel,
        LiefengContent,
        LiefengTable,
        LiefengModal,
        LiefengTree,
        Formo,
        Service,
        Chart,
        Statistics,
        Message,
    },
}
</script>

<style scoped lang="less">
.isForm {
    display: flex;
    flex-wrap: wrap;

    .isWidth {
        width: 40%;
        margin-right: 40px;
    }

    .isNoWidth {
        width: 100%;
    }
}

.export-modal {
    /deep/ #modal_contentarea {
        overflow: visible !important;
        height: 270px;
    }
}

/deep/ .validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
</style>
