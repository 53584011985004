<template>
  <div>
      <div class="location">
        <Input
            placeholder="请输入搜索的地址"
            v-model="inputValue"
            style="width: 300px;"
        />
        <Button type="info" style="margin-left: 10px" @click="saveFn">搜索</Button>
      </div>
      <div class="location-div">当前点击坐标为：<span class="position">经度:{{pageCenter.lng}} 纬度:{{pageCenter.lat}}</span></div>
      <baidu-map :center="pageCenter" :zoom="zoom"  @ready="handler"  :ak="$store.state.baiduAk" :clicking='true' @click="clickMap" :scroll-wheel-zoom="true" style="width:100%;height:647px;margin: 10px auto" >
        <bm-marker
        :position="{ lng: pageCenter.lng, lat: pageCenter.lat }"
        :dragging="true"
        animation="BMAP_ANIMATION_BOUNCE"
        >
        </bm-marker>
        <bm-view style="width: 100%; height:100%; flex: 1"></bm-view>
        <bm-local-search :keyword="addressKeyword"  :auto-viewport="true" style="display: none"></bm-local-search>
        <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>  
      </baidu-map>
  </div>
</template>

<script>
 import {BaiduMap, BmMarker, BmLocalSearch,BmView,BmGeolocation} from 'vue-baidu-map'
  export default {
    //import引入的组件需要注入到对象中才能使用
    components:{BaiduMap, BmMarker, BmLocalSearch,BmView,BmGeolocation},
    props: {
        // 绑定一个值，每次改变都会执行
        showNum:{
            type:Number,
            default:()=>{
                return 0
            }
        },
        // 绑定一个字符串，根据这个字符串进入页面自动搜索
        localName:{
            type:String,
            default:()=>{
                return ''
            }
        },
        center:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data() {
      //这里存放数据
      return {
          pageCenter: {lng: 113, lat:23},
          zoom: 15,
          BMap:null,
          map:null,
          inputValue:'',
          addressKeyword:''
      }
    },
    //方法集合
    methods: {
        //点击搜索按钮
        saveFn(){
             this.addressKeyword = this.inputValue
        },
        // 点击了地图
        clickMap(e){
            // document.activeElement.blur();
            this.pageCenter.lng = e.point.lng
            this.pageCenter.lat = e.point.lat  
            this.zoom = 20
            this.$emit('clickMap',this.pageCenter)
        },
        handler ({BMap, map}) {
            this.BMap = BMap
            this.map = map   
            // this.pageCenter.lng = 113.324
            // this.pageCenter.lat = 23.114    //天安门经纬度
            this.zoom = 15
        
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {},
    watch:{
        showNum:{
            handler(val,newVal){
                if(val){
                    if(this.center.lat && this.center.lat != '' && this.center.lng && this.center.lng != ''){
                        this.pageCenter.lng = this.center.lng
                        this.pageCenter.lat = this.center.lat
                        this.zoom = 20
                        this.$emit('clickMap',this.pageCenter)
                        this.inputValue = this.localName
                    }else{
                        this.pageCenter.lng = ''
                        this.pageCenter.lat = ''
                        if(this.localName != ''){
                            this.inputValue = this.localName
                            this.addressKeyword = this.localName
                        }
                    }
                    
                }
            }
        }
    }
  }
</script>
<style lang='less' scoped>
  //编写css
</style>