<template>
    <div class="form-div" v-if="isShowDiv">
        <div class="equiment-title">基本信息</div>
        <Form class="isForm" label-position="right" :label-width="150" v-if="companyInfoRo" :disabled="isWacth">
            <FormItem class="isWidth">
                <span slot="label" class="validate">单位名称:</span>
                <Input placeholder="请填写一般情况下对外的名称" :maxlength="20" v-model.trim="formdata.name" :disabled="isWacth" />
            </FormItem>
            <FormItem class="isWidth">
                <span slot="label">单位性质:</span>
                <Select clearable v-model="formdata.nature" :disabled="isWacth">
                    <Option v-for="item in natureType" :value="item.dictKey" :key="item.dictKey">{{ item.dictValue }}</Option>
                </Select>
                <Tooltip style="margin-left:10px" placement="bottom">
                    <span style="padding: 5px 5px 5px 10px;background: #2d8cf0;color: rgb(255, 255, 255);border-radius: 50%;font-weight: bold;">？</span>
                    <div class="isContent" slot="content">
                        <p>个体：按照国家有关规定，领取营业执照，个人单独从事生产、饮食、修理、销售等行业的个体劳动者。</p>
                        <p>企业/公司/法人：向市场提供商品或服务，实行自主经营、自负盈亏、独立核算的法人或其他社会经济组织。</p>
                        <p>国企：国有企业分为中央企业（由中央政府监督管理的国有企业）和地方企业（由地方政府监督管理的国有企业）。</p>
                        <p>
                            社会组织/社会团体：是指为一定目的由一定人员组成的社会组织，还要制定章程、到有关机关登记，有的还须依法
                            <br />
                            申请许可等等。如宗教、科技、文化、艺术、慈善事业等社会群众团体。
                        </p>
                        <p>
                            机关/事业单位：机关单位，指国家机关，指从事国家管理和行使国家权力的机关；事业单位是指国家为了社会公益
                            <br />
                            目的，由国家机关举办或者其他组织利用国有资产举办的，从事教育、科技、文化、卫生等活动的社会服务组织。
                        </p>
                        <p>其他：不在以上情况里。</p>
                    </div>
                </Tooltip>
            </FormItem>
            <!-- <FormItem class="isWidth">
                <span slot="label" class="validate">所属社区:</span>
                <Cascader :disabled="isWacth" v-model="formdata.orgCode" :data="publishList" :load-data="loadCommunity" change-on-select @on-change="changeCascader" style="width:100%"></Cascader>
            </FormItem> -->
            <FormItem class="isWidth">
                <span slot="label" class="validate">所属社区:</span>
                <elem-cascader @changeCasader="onChangeCasader($event, formdata)" :orgCode="4401" :width="300" :value="formdata.community"></elem-cascader>
            </FormItem>
            <FormItem class="isWidth">
                <span slot="label" class="validate">联系地址:</span>
                <Input placeholder="请填写联系地址" :maxlength="50" v-model.trim="formdata.addr" :disabled="isWacth" />
            </FormItem>
            <FormItem class="isWidth">
                <span slot="label">经度纬度:</span>
                <Input :disabled="isWacth" placeholder="请输入经度" type="number" v-model.trim="formdata.longitude" :maxlength="40" style="flex:1"></Input>
                <Input :disabled="isWacth" placeholder="请输入纬度" type="number" v-model.trim="formdata.latitude" :maxlength="40" style="flex:1;margin-left:10px"></Input>
                <Button type="primary" style="margin-left:10px" @click="openMap" :disabled="isWacth">选择</Button>
            </FormItem>
            <FormItem class="isWidth">
                <span slot="label">安全生产管理人:</span>
                <Input placeholder="请填写一般情况下优先联系的负责人" :maxlength="20" v-model.trim="formdata.principal" :disabled="isWacth" />
            </FormItem>
            <FormItem class="isWidth">
                <span slot="label">联系电话:</span>
                <Input placeholder="请填写联系电话" :maxlength="20" v-model.trim="formdata.mobile" :disabled="isWacth" />
            </FormItem>
            <FormItem class="isWidth">
                <span slot="label">微信号:</span>
                <Input placeholder="请填写微信号" :maxlength="20" v-model.trim="formdata.wechat" :disabled="isWacth" />
            </FormItem>
            <!-- <FormItem class="isWidth">
              <span slot="label" >在生活圈里的分类:</span>
             <Select clearable v-model="companyInfoRo.lifeType" :disabled="isWacth" >
                <Option
                v-for="item in lifeType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
            </Select>
          </FormItem> -->
            <!-- <FormItem class="isWidth">
              <span slot="label" >在生活圈里是否显示:</span>
              <i-switch style="margin-top:5px" v-model="companyInfoRo.lifeDisplay" :true-value="'1'" :false-value="'2'" :disabled="isWacth">
                <span slot="open">开</span>
                <span slot="close">关</span>
            </i-switch>
          </FormItem> -->
            <FormItem class="isNoWidth image">
                <span slot="label">封面图片:</span>
                <elem-upload :value="formdata.cover_image" @on-change="formdata.cover_image = $event.value"></elem-upload>
                <!-- <LiefengUpload
                    target="1"
                    @success="uploadSuccess"
                    v-if="showLieFengUpload"
                    type="image"
                    accept=".jpg,.png,.gif,.jpeg"
                    :defaultList="companyInfoRoFileUrl"
                    :format="['jpg', 'png', 'jpeg', 'gif']"
                    :showView="true"
                    :showDel="false"
                ></LiefengUpload>
                <Button type="error" v-if="companyInfoRoFileUrl.length != 0 && isWacth == false" @click="deleteInfo">删除图片</Button> -->
            </FormItem>
        </Form>

        <div class="equiment-title">扩展信息</div>
        <Form class="isForm" label-position="right" :label-width="150" v-if="companyExpandRo">
            <FormItem class="isWidth">
                <span slot="label">单位全称:</span>
                <Input placeholder="请填写营业执照上的名称" :maxlength="20" v-model.trim="formdata.fullName" :disabled="isWacth" />
            </FormItem>
            <FormItem class="isWidth">
                <span slot="label">行业分类:</span>
                <Select clearable multiple :max-tag-count="3" placeholder="请选择行业分类" v-model="formdata.industryCode" :disabled="isWacth">
                    <Option v-for="item in industryList" :value="item.dictKey" :key="item.dictKey">{{ item.dictValue }}</Option>
                </Select>
            </FormItem>
            <FormItem class="isWidth">
                <span slot="label">从业人数:</span>
                <Select clearable placeholder="请选择从业人数范围" v-model="formdata.staffAmount" :disabled="isWacth">
                    <Option v-for="item in staffAmount" :value="item.dictKey" :key="item.dictKey">{{ item.dictValue }}</Option>
                </Select>
            </FormItem>
            <FormItem class="isWidth">
                <span slot="label">经营或办公面积:</span>
                <Input placeholder="请填写经营或办公面积" :maxlength="20" v-model.trim="formdata.businessArea" :disabled="isWacth" />
                <span style="margin-left:10px;width:100px">平方米</span>
            </FormItem>
            <FormItem class="isWidth">
                <span slot="label">入驻时间:</span>
                <DatePicker style="width:100%" type="date" v-model="formdata.startDate" :disabled="isWacth"></DatePicker>
            </FormItem>
            <FormItem class="isWidth">
                <span slot="label">营业状态:</span>
                <RadioGroup v-model="formdata.businessStatus">
                    <Radio label="1" :disabled="isWacth">营业中</Radio>
                    <Radio label="2" :disabled="isWacth">已停业</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem class="isWidth">
                <span slot="label">法人代表或负责人:</span>
                <Input placeholder="请填写法人代表或负责人" :maxlength="20" v-model.trim="formdata.chargeName" :disabled="isWacth" />
            </FormItem>
            <FormItem class="isWidth" v-if="companyExpandRo.businessStatus == 2">
                <span slot="label" class="validate">停业时间:</span>
                <DatePicker style="width:100%" type="date" v-model="formdata.endDate" :disabled="isWacth"></DatePicker>
            </FormItem>
            <FormItem class="isWidth">
                <span slot="label">联系电话:</span>
                <Input placeholder="请填写联系电话" :maxlength="20" v-model.trim="formdata.chargeMobile" :disabled="isWacth" />
            </FormItem>
            <FormItem class="isWidth">
                <span slot="label">身份证号:</span>
                <Input placeholder="请填写身份证号" :maxlength="18" v-model.trim="formdata.chargeIdNum" :disabled="isWacth" />
            </FormItem>
            <FormItem class="isNoWidth image">
                <span slot="label">营业执照:</span>
                <elem-upload :value="formdata.business_license" @on-change="formdata.business_license = $event.value"></elem-upload>
                <!-- <LiefengUpload
                    target="2"
                    @success="uploadSuccessZhi"
                    v-if="showLiefengUploadZhi"
                    type="image"
                    accept=".jpg,.png,.gif,.jpeg"
                    :defaultList="companyExpandRoFileUrl"
                    :format="['jpg', 'png', 'jpeg', 'gif']"
                    :showView="true"
                    :showDel="false"
                ></LiefengUpload>
                <Button type="error" v-if="companyExpandRoFileUrl.length != 0 && isWacth == false" @click="deleteExpand">删除营业执照</Button> -->
            </FormItem>
            <FormItem class="isNoWidth">
                <span slot="label">基础介绍:</span>
                <Input placeholder="请填写基础介绍" type="textarea" :maxlength="400" v-model.trim="formdata.remark" :rows="6" style="width:80%" :disabled="isWacth" />
            </FormItem>
        </Form>
        <LiefengModal class="map" :fullscreen="false" width="80%" title="选择经纬度" @input="mapFn" :value="mapStatus">
            <template #contentarea style="width: 100%;">
                <Map :showNum="showNum" :localName="localName" :center="center" @clickMap="clickMap"></Map>
            </template>
            <template #toolsbar>
                <Button
                    type="info"
                    style="margin-right: 10px"
                    @click="
                        mapTip = true
                        mapFn(false)
                    "
                >
                    取消
                </Button>
                <Button type="primary" @click="saveMap">确定</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengUpload from "@/components/LiefengUpload"
import LiefengModal from "@/components/LiefengModal"
import Map from "./map.vue"
import ElemUpload from "../../jointly/components/elem-upload.vue"
import ElemCascader from "../../jointly/components/elem-cascader.vue"
import Utils from "../../jointly/utils/utils"

export default {
    components: { LiefengUpload, Map, LiefengModal, ElemUpload, ElemCascader },
    props: {
        formdata: {
            type: Object,
            default: {},
        },
        companyInfoRo: {
            type: Object,
            default: () => {
                return {}
            },
        },
        isShow: {
            type: Number,
            default: () => {
                return 0
            },
        },
        companyExpandRo: {
            type: Object,
            default: () => {
                return {}
            },
        },
        companyInfoRoFileUrl: {
            type: Array,
            default: () => {
                return []
            },
        },
        companyExpandRoFileUrl: {
            type: Array,
            default: () => {
                return []
            },
        },
        isWacth: {
            type: Boolean,
            default: () => {
                return false
            },
        },
    },
    data() {
        return {
            isShowDiv: false, //整个div的控制显示
            natureType: [], //单位性质
            lifeType: [], //生活圈分类
            publishList: [], //所属社区
            showLieFengUpload: true, //是否显示上传组件
            showLiefengUploadZhi: true, //是否显示营业执照
            industryList: [], // 行业分类
            staffAmount: [], //从业人数范围

            // 地图模态框
            mapStatus: false,
            mapTip: false,
            showNum: 0,
            localName: "",
            center: {
                lat: "",
                lng: "",
            },
            clickCenter: {
                lat: "",
                lng: "",
            },
        }
    },
    methods: {
        // 方法部分
        // 点击地图传出来的经纬度
        clickMap(val) {
            this.clickCenter.lng = val.lng
            this.clickCenter.lat = val.lat
        },
        // 选择经纬度确定按钮
        saveMap() {
            this.mapStatus = false
            this.localName = ""
            if (this.clickCenter.lng != "" && this.clickCenter.lat != "") {
                this.center = {
                    lat: this.clickCenter.lat,
                    lng: this.clickCenter.lng,
                }

                this.$set(this.formdata, "longitude", this.clickCenter.lng)
                this.$set(this.formdata, "latitude", this.clickCenter.lat)
            }
        },
        // 点击选择经纬度按钮
        openMap() {
            this.mapStatus = true
            ++this.showNum
            this.localName = ""
            if (this.companyInfoRo.longitude && this.companyInfoRo.longitude != "") {
                let data = this.companyInfoRo.longitude.split(",")
                this.center = {
                    lng: data[0],
                    lat: data[1],
                }
            }
            if (this.companyInfoRo.orgCode && this.companyInfoRo.orgCode.length != 0) {
                if (this.companyInfoRo.orgCode.length == 1) {
                    this.formatterTree(this.publishList, this.companyInfoRo.orgCode[0])
                } else if (this.companyInfoRo.orgCode.length == 2) {
                    this.formatterTree(this.publishList, this.companyInfoRo.orgCode[0])
                    this.formatterTree(this.publishList, this.companyInfoRo.orgCode[1])
                } else if (this.companyInfoRo.orgCode.length == 3) {
                    this.formatterTree(this.publishList, this.companyInfoRo.orgCode[0])
                    this.formatterTree(this.publishList, this.companyInfoRo.orgCode[1])
                    this.formatterTree(this.publishList, this.companyInfoRo.orgCode[2])
                }
            } else {
                this.localName = ""
            }
        },
        formatterTree(data, id) {
            if (data && data.length != 0) {
                data.map(item => {
                    if (item.value == id) {
                        this.localName = item.label + this.localName
                    } else {
                        if (item.children && item.children.length != 0) {
                            this.formatterTree(item.children, id)
                        }
                    }
                })
            }
        },

        // 关闭模态框
        mapFn(status) {
            if (!status && this.mapTip) {
                this.mapStatus = status
                this.mapTip = false
            } else {
                this.mapStatus = status
            }
        },
        // 封面图片删除按钮
        deleteInfo() {
            this.$emit("deleteInfo")
        },
        // 营业执照删除按钮
        deleteExpand() {
            this.showLiefengUploadZhi = false
            this.$nextTick(() => {
                this.showLiefengUploadZhi = true
            })
            this.$emit("deleteExpand")
        },
        // 选择所属社区回调事件
        changeCascader(val, value) {},
        uploadSuccess(target, file) {
            this.$emit("uploadSuccess", file)
        },
        uploadSuccessZhi(target, file) {
            this.$emit("uploadSuccessZhi", file)
        },
        // 获取接口数据
        getDictType(type, model) {
            this.$get("/datamsg/api/common/sys/findDictByDictType", {
                dictType: type,
            }).then(res => {
                if (res.code == 200) {
                    this[model] = res.dataList
                }
            })
        },
        getPulishData(code, list) {
            this.$get("/gx/pc/organization/selectCascadeDataScope", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                orgCode: code,
            }).then(res => {
                if (res.code == 200) {
                    this[list] = res.dataList.map(item => {
                        return {
                            value: item.orgCode,
                            label: item.orgName,
                            orgLevel: item.orgLevel,
                            loading: false,
                            children: [],
                        }
                    })
                }
            })
        },
        // 级联选择异步选择
        loadCommunity(item, callback) {
            item.loading = true
            let arrCode = item.value
            this.$get("/gx/pc/organization/selectCascadeDataScope", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                orgCode: arrCode,
            }).then(res => {
                if (res.code == 200) {
                    if (!item.index) {
                        item.children = res.dataList.map(item => {
                            return {
                                value: item.orgCode,
                                label: item.orgName,
                                orgLevel: item.orgLevel,
                                loading: false,
                                children: [],
                                index: 1,
                            }
                        })
                        if (this.companyInfoRo.changeOrgArr && this.companyInfoRo.changeOrgArr.length > 0) {
                            this.companyInfoRo.orgCode = this.companyInfoRo.orgCode.concat(this.companyInfoRo.changeOrgArr[1], this.companyInfoRo.changeOrgArr[2])
                            this.companyInfoRo.orgCode = Array.from(new Set(this.companyInfoRo.orgCode))
                            this.companyInfoRo.changeOrgArr = this.companyInfoRo.changeOrgArr.slice(0, this.companyInfoRo.changeOrgArr.length)
                        }
                    } else {
                        item.children = res.dataList.map(item => {
                            return {
                                value: item.orgCode,
                                label: item.orgName,
                                orgLevel: item.orgLevel,
                                index: 1,
                            }
                        })
                        if (this.companyInfoRo.changeOrgArr && this.companyInfoRo.changeOrgArr.length > 0) {
                            this.companyInfoRo.orgCode = this.companyInfoRo.orgCode.concat(this.companyInfoRo.changeOrgArr[1], this.companyInfoRo.changeOrgArr[2])
                            this.companyInfoRo.orgCode = Array.from(new Set(this.companyInfoRo.orgCode))
                            this.companyInfoRo.changeOrgArr = this.companyInfoRo.changeOrgArr.slice(0, this.companyInfoRo.changeOrgArr.length)
                        }
                    }
                    item.loading = false
                    callback()
                }
            })
        },

        /**
         * 监听社区改变事件
         */
        onChangeCasader(evt) {
            Utils.each(evt, (v, i) => {
                this.$set(this.formdata, ["zoneCode", "streetCode", "communityCode"][i], v)
            })
        },
    },
    watch: {
        isShow: {
            handler(val) {
                if (val) {
                    console.log(this)
                    this.isShowDiv = false
                    this.$nextTick(() => {
                        this.isShowDiv = true
                    })
                    this.getDictType("UNIT_NATURE", "natureType")
                    this.getDictType("LIFE_CIRCLE_TYPE", "lifeType")
                    this.getDictType("INDUSTRY_TYPE", "industryList")
                    this.getDictType("SCOPE_OF_EMPLOYMENT", "staffAmount")
                    this.getPulishData(4401, "publishList")
                }
            },
        },
        companyInfoRoFileUrl: {
            handler(val, newVal) {
                if (val) {
                    this.showLieFengUpload = false
                    this.$nextTick(() => {
                        this.showLieFengUpload = true
                    })
                }
            },
        },
        companyExpandRoFileUrl: {
            handler(val) {
                if (val) {
                    this.showLiefengUploadZhi = false
                    this.$nextTick(() => {
                        this.showLiefengUploadZhi = true
                    })
                }
            },
        },
    },
}
</script>

<style lang="less" scoped>
.form-div {
    width: 100%;
    .isForm {
        display: flex;
        flex-wrap: wrap;
        .isWidth {
            width: 40%;
            margin-right: 40px;
        }
        .isNoWidth {
            width: 100%;
        }
    }
}
.equiment-title {
    position: relative;
    padding-left: 20px;
    margin: 10px 0;
    &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 5px;
        height: 20px;
        background-color: #2db7f5;
    }
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
/deep/.ivu-date-picker {
    display: inline;
}
/deep/.ivu-tooltip-inner {
    width: 750px !important;
    max-width: none !important;
}
/deep/.ivu-form-item-content {
    display: flex;
}
.image {
    /deep/.ivu-form-item-content {
        display: block;
    }
}
.map {
    /deep/.ivu-icon-ios-close {
        display: none;
    }
}
</style>
